import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import blogStyles from "./blog.module.scss"
import Head from "../components/head"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMM D, YYYY")
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title="Blog" />
      <h1 className="mb-5">Blog</h1>
      <div className={blogStyles.grid}>
        {data.allContentfulBlogPost.edges.map(edge => {
          return (
            <Link to={`/blog/${edge.node.slug}`} className={blogStyles.card} key={edge.node.slug}>
              <h2>{edge.node.title} &rarr;</h2>
              <p className="small text-muted">
                Posted on {edge.node.publishedDate}
              </p>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogPage
